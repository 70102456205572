@use '../../styles/variables';
@use '../../styles/mixins';

@mixin getButtonColors($property, $default, $hover, $active, $disabled: null) {
  #{$property}: $default;

  &:not(:disabled) {
    &:hover {
      #{$property}: $hover;
    }

    &:active {
      #{$property}: $active;
    }
  }

  @if $disabled {
    &:disabled {
      #{$property}: $disabled;
    }
  }
}

.commonStyles {
  @include mixins.transition(background-color, color);
  cursor: pointer;
  display: flex;
  text-align: center;
  user-select: none;

  &:disabled {
    color: variables.$color-grey-70;
    cursor: default;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }
}

.fullWidth {
  width: 100%;
}

.button {
  position: relative;

  &.buttonWithLoading {
    transition: none;
  }

  &.small,
  &.medium {
    @include mixins.m-medium-slh;
    border-radius: variables.$border-radius-m;
  }

  &.small {
    padding: variables.$indent-xs;
  }

  &.small .text {
    padding: 0 variables.$indent-xxs;
  }

  &.medium {
    padding: variables.$indent-s;
  }

  &.medium .text {
    padding: 0 variables.$indent-xs;
  }

  &.large {
    @include mixins.l-medium;
    border-radius: variables.$border-radius-l;
    min-width: 56px;
    padding: variables.$indent-m variables.$indent-s;
  }

  &.large .text {
    padding: 0 variables.$indent-xs;
  }
}

.linkStyled {
  text-align: inherit;

  &.small,
  &.medium {
    @include mixins.m-medium;
    column-gap: variables.$indent-xxs;
  }

  &.large {
    @include mixins.l-medium;
    column-gap: variables.$indent-xxs;
  }
}

.contentContainerIfLoading {
  display: inline-flex;
  visibility: hidden;
}

.loadingIcon {
  color: variables.$color-grey-70;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.blackButton {
  @include getButtonColors(
    background-color,
    variables.$color-grey-10,
    variables.$color-grey-20,
    variables.$color-grey-10,
    variables.$color-grey-98
  );
}

.blackTonalButton {
  @include getButtonColors(
    background-color,
    variables.$color-grey-98,
    variables.$color-grey-98,
    variables.$color-grey-95,
    variables.$color-grey-98
  );
}

.blackGhostButton {
  @include getButtonColors(
    background-color,
    transparent,
    variables.$color-grey-98,
    variables.$color-grey-98,
    variables.$color-grey-98
  );
}

.greyGhostButton {
  @include getButtonColors(
    background-color,
    transparent,
    variables.$color-grey-98,
    variables.$color-grey-95,
    variables.$color-grey-98
  );
}

.accentTonalButton {
  @include getButtonColors(
    background-color,
    variables.$color-purple-95,
    variables.$color-purple-90,
    variables.$color-purple-90,
    variables.$color-purple-98
  );

  &.buttonWithLoading {
    background: variables.$color-purple-98;
    color: variables.$color-purple-70;
  }
}

.whiteText {
  @include getButtonColors(
    color,
    variables.$color-white,
    variables.$color-white,
    variables.$color-white
  );
}

.blackText {
  @include getButtonColors(
    color,
    variables.$color-grey-10,
    variables.$color-grey-30,
    variables.$color-grey-20,
    variables.$color-grey-70
  );
}

.greyText {
  @include getButtonColors(
    color,
    variables.$color-grey-50,
    variables.$color-grey-50,
    variables.$color-grey-50,
    variables.$color-grey-70
  );
}

.linkStyledGreyText {
  @include getButtonColors(
    color,
    variables.$color-grey-50,
    variables.$color-grey-30,
    variables.$color-grey-20,
    variables.$color-grey-70
  );
}

.linkStyledBlueText {
  @include getButtonColors(
    color,
    variables.$color-blue-50,
    variables.$color-blue-60,
    variables.$color-blue-40,
    variables.$color-grey-95
  );
}

.purpleText {
  @include getButtonColors(
    color,
    variables.$color-purple-30,
    variables.$color-purple-50,
    variables.$color-purple-30,
    variables.$color-purple-70
  );
}

.clickableIconContainer {
  display: flex;
}
