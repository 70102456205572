@use 'styles/variables';

.icon {
  color: variables.$color-grey-50;

  &:hover {
    color: variables.$color-grey-10;
  }
}

.form {
  align-items: center;
  display: flex;
  gap: variables.$indent-xs;
  justify-content: space-between;
  width: 100%;
}

.inputWrapper {
  width: 100%;
}

.input {
  background-color: variables.$color-special-base-bg;
  border-radius: variables.$border-radius-s;
}
