@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.s-medium;
  align-items: center;
  background-color: variables.$color-white;
  color: variables.$color-grey-50;
  display: flex;
  gap: variables.$indent-xxs;
  height: 48px;
  padding-block: variables.$indent-m;

  &.alignLeft {
    justify-content: start;
    padding-inline: 20px variables.$indent-s;
    text-align: left;
  }

  &.alignRight {
    justify-content: end;
    padding-inline: variables.$indent-s 20px;
    text-align: right;
  }

  &.alignCenter {
    justify-content: center;
    padding-inline: variables.$indent-s;
    text-align: center;
  }
}

.content {
  max-width: 100%;
  overflow: hidden;
}

.pinned {
  position: sticky;
  z-index: 1;
}

.sortable {
  @include mixins.transition(background-color);

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: variables.$color-grey-98;
  }
}

@include mixins.less-than-sm {
  .pinned {
    border-right: 0;
    left: 0 !important;
    position: relative;
  }
}
