@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  color: variables.$color-grey-40;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.emptyDataElement {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: -5%;
}

.image {
  display: flex;
  margin-bottom: variables.$indent-xs;
}

.text {
  @include mixins.l-regular;
  text-align: center;
}
