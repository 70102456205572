@use 'styles/variables';

.wrapper {
  position: relative;
}

.innerButtonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}

.icon {
  align-items: center;
  display: flex;
  margin-right: variables.$indent-xxs;
}
