@use 'styles/variables';
@use 'styles/mixins';

$content-min-width: 393px;
$content-base-width: 496px;

$top-bottom-padding: 32px;
$left-right-padding-large: 112px;
$left-right-padding-medium: variables.$indent-l;
$left-right-padding-small: variables.$indent-m;

.authLayout {
  display: flex;
  min-height: 100dvh;
  position: relative;
}

.line {
  background-color: variables.$color-grey-95;
  width: 1px;
}

.content {
  box-sizing: content-box;
  flex-basis: $content-base-width;
  min-width: $content-min-width;
  padding: $top-bottom-padding $left-right-padding-large;
  position: relative;
}

.background {
  box-sizing: content-box;
  display: flex;
  flex-basis: $content-base-width;
  flex-direction: column;
  margin: 0 auto;
  min-width: $content-min-width;
  padding: $top-bottom-padding $left-right-padding-large 0;
  position: relative;
  row-gap: variables.$indent-xxl;
}

@include mixins.less-than-lg {
  .content {
    margin: 0 auto;
    padding: $top-bottom-padding $left-right-padding-medium;
  }

  .background {
    padding: $top-bottom-padding $left-right-padding-medium 0;
  }
}

@include mixins.less-than-md {
  .line {
    display: none;
  }

  .content {
    max-width: initial;
  }

  .background {
    display: none;
  }
}

@include mixins.less-than-sm {
  .content {
    box-sizing: border-box;
    flex-basis: auto;
    min-width: auto;
    padding: $top-bottom-padding $left-right-padding-small;
    width: 100%;
  }
}
