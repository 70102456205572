@use 'styles/mixins';
@use 'styles/variables';

$border-width: 1px;
$dropdown-min-height: 326px;

.listContainer {
  max-height: $dropdown-min-height;
  overflow-y: auto;
  padding: variables.$indent-xs 0;
}

.listItem:not(:last-child) {
  margin-bottom: variables.$indent-xxs;
}

.button {
  @include mixins.listItem;
  @include mixins.transition(color);

  color: variables.$color-grey-10;
  width: 100%;
}

.withLabelContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:not(:last-child) {
    margin-bottom: variables.$indent-xxs;
  }

  .labelSub {
    @include mixins.m-regular;
    color: variables.$color-grey-10;
  }
}

.button.highlightOnHover:hover {
  background-color: variables.$color-grey-98;
}

.button {
  .withResetWidth {
    flex-grow: unset;
  }
}
