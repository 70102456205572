@use 'styles/mixins';

.small,
.medium {
  @include mixins.m-medium;
}

.large {
  @include mixins.l-medium;
}
