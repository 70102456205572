@use 'styles/variables';
@use 'styles/mixins';

.rowContainer {
  border-bottom: 1px solid variables.$color-grey-95;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  &:nth-last-of-type(2) {
    /*
    * Need to adjust margin-bottom to prevent
    * overlapping of the last row's border
    * with the table's border
    */
    margin-bottom: -1px;
  }

  &:not(.withExpand) > .pinned {
    border-right: 1px solid variables.$color-grey-95;
  }

  &.withExpand > :nth-child(2) {
    border-right: 1px solid variables.$color-grey-95;
    padding-left: 0;
  }

  &.withExpand > :nth-child(1) {
    padding-left: variables.$indent-m;
    padding-right: 0;
  }
}

.clickable {
  cursor: pointer;

  &:hover > * {
    background-color: variables.$color-grey-98;
  }
}

.nestedFooterContainer {
  display: grid;
  grid-column: 1 / -1;
}

@include mixins.less-than-sm {
  .rowContainer {
    overflow: hidden;
  }
}
