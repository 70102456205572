@use 'styles/variables';
@use 'styles/mixins';

.button {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.buttonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}

.activeBlackTonalButton,
.activeBlackTonalButton:not(:disabled):hover {
  background-color: variables.$color-grey-90;
  color: variables.$color-grey-20;
}

.activeAccentTonalButton,
.activeAccentTonalButton:not(:disabled):hover {
  background-color: variables.$color-purple-90;
  color: variables.$color-purple-50;
}

.icon {
  @include mixins.transition(rotate);
  display: flex;

  &.activeIcon {
    rotate: 180deg;
  }
}
