@use 'styles/variables';
@use 'styles/mixins';

.button {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.buttonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}

.innerButtonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}

.icon {
  @include mixins.transition(rotate);

  &.activeIcon {
    rotate: 180deg;
  }
}

@include mixins.less-than-md {
  .innerButtonText,
  .icon {
    display: none;
  }

  .textWrapper {
    padding: 0 !important;
  }
}
