@use 'styles/variables';

.contentContainer,
.noReason {
  margin-bottom: variables.$indent-xl;
}

.contentContainer {
  .declineReason {
    margin-bottom: variables.$indent-xl;
  }
}
