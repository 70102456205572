@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 72px;
  min-height: 52px;
  padding-block: variables.$indent-m;

  > * {
    max-width: 100%;
  }

  &.alignLeft {
    justify-content: start;
    padding-inline: 20px variables.$indent-s;
    text-align: left;
  }

  &.alignRight {
    justify-content: end;
    padding-inline: variables.$indent-s 20px;
    text-align: right;
  }

  &.alignCenter {
    justify-content: center;
    padding-inline: variables.$indent-s;
    text-align: center;
  }

  &:not(.disableOverflow) {
    overflow: hidden;
  }
}

.pinned {
  background-color: variables.$color-white;
  position: sticky;
  z-index: 1;
}

.hovered {
  @include mixins.transition(background-color);

  background-color: variables.$color-grey-98;
}

.summaryCell {
  background-color: variables.$color-grey-98;
}

@include mixins.less-than-sm {
  .container {
    max-height: 64px;
    min-height: 44px;
    padding-block: variables.$indent-s;
  }

  .pinned {
    border-right: 0;
    left: 0 !important;
    position: relative;
  }
}
