@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: variables.$indent-xxs;
  max-height: 306px;
  overflow-y: auto;
  padding-block: variables.$indent-xs;
}

.checkbox {
  padding: variables.$indent-xs;
}

@include mixins.less-than-md {
  .container {
    padding: 0;
  }
}

@include mixins.less-than-sm {
  .container {
    max-height: 100%;
  }
}
