@use 'styles/mixins';
@use 'styles/variables';

$border-line-width: 1px;
$search-input-height: 58px;

.menu.menu {
  @include mixins.dropdownContainer;

  left: -(variables.$indent-s) - $border-line-width;
  top: (variables.$indent-l + variables.$indent-xs + variables.$indent-xs);
  width: calc(100% + (variables.$indent-s * 2 + $border-line-width * 2));
  z-index: variables.$dropdown-z-index;
}

.menuAsBottomSheet.menuAsBottomSheet,
.menuAsPopUp.menuAsPopUp {
  height: 100%;
  position: relative;
  top: 0;

  &.withSearchInput {
    height: calc(100% - $search-input-height);
  }
}
