@use 'styles/variables';
@use 'styles/mixins/typography';

.form {
  margin-bottom: variables.$indent-m;
}

.passwordSetting {
  display: flex;
  justify-content: flex-end;
  margin-bottom: variables.$indent-xl;
}

.forgetPasswordLink {
  @include typography.m-medium;
}
