@use 'styles/variables';
@use 'styles/mixins';

.identitiesContainer {
  display: flex;
  gap: variables.$indent-m;
  justify-content: space-between;
  width: 100%;

  > div {
    flex-grow: 1;
  }
}

@include mixins.less-than-sm {
  .identitiesContainer {
    flex-direction: column;
  }
}
