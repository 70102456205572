@use 'styles/variables';
@use 'styles/mixins';

$body-content-max-width: 1216px;

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100vh;
}

.layout {
  background-color: variables.$color-special-base-bg;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: hidden;
  padding-block: 32px;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $body-content-max-width;
  padding-inline: variables.$indent-m;
  position: relative;
}

.contentWrapper {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-xl;
  display: flex;
  flex-basis: 100%;
  gap: variables.$indent-xl;
  overflow-y: auto;
  padding: 48px variables.$indent-xxxl 48px variables.$indent-xl;
  scroll-behavior: smooth;
}

.scrollButtonWrapper {
  bottom: 32px;
  position: absolute;
  right: 32px;
}

@include mixins.less-than-md {
  .contentWrapper {
    flex-direction: column;
    padding: variables.$indent-xl 32px;
  }
}

@include mixins.less-than-sm {
  .container {
    max-height: 100%;
  }

  .layout {
    padding-bottom: 0;
  }

  .contentWrapper {
    border-radius: 0;
    margin: 0 -#{variables.$indent-m};
    padding: 32px variables.$indent-m;
  }

  .scrollButtonWrapper {
    bottom: variables.$indent-m;
    position: fixed;
    right: variables.$indent-m;
  }
}
