@use '~destyle.css/destyle';
@use './font-face';
@use './variables';
@use './mixins';

/* stylelint-disable-next-line selector-max-id */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  min-width: initial;
}

body {
  @include mixins.l-regular;
  color: variables.$color-grey-10;
  font-family: variables.$font-family;
  min-width: variables.$min-app-width;
}

.scrollLocked {
  overflow: hidden;
}

h1 {
  @include mixins.h1;
}

h2 {
  @include mixins.h2;
}

h3 {
  @include mixins.h3;
}

.specHeaderLarge {
  @include mixins.spec-header-large;
}

p {
  @include mixins.paragraph;
}
