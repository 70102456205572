@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  display: flex;
}

.period {
  @include mixins.m-medium-slh;

  color: variables.$color-grey-50;
  margin-right: variables.$indent-xxs;
}

.bottomSheetContent,
.popUpContent {
  padding: 0;
}
