@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: variables.$indent-xl;
  text-align: center;
}

.content {
  margin-top: 18.85vh;
  max-width: 510px;
}

.logo {
  height: 32px;
}

@include mixins.less-than-md {
  .container {
    padding: variables.$indent-l variables.$indent-m;
  }
}
