@use '../../styles/variables';
@use '../../styles/mixins';

$icon-size: 32px;
$mobile-default-indent: 28px;

.container {
  display: flex;
  height: 40px;
  justify-content: space-between;

  &.defaultMargin {
    margin-bottom: variables.$indent-xl;
  }
}

.titleContainer {
  align-items: center;
  display: flex;
}

.iconContainer {
  margin-right: variables.$indent-xs;
}

.icon {
  color: variables.$color-vavada-pink-60;
  height: $icon-size;
  width: $icon-size;
}

.title {
  color: variables.$color-grey-10;
}

.mobileButton {
  display: none;
}

@include mixins.less-than-sm {
  .container.defaultMargin {
    margin-bottom: $mobile-default-indent;
  }

  .iconContainer {
    display: none;
  }

  .desktopButton {
    display: none;
  }

  .mobileButton {
    display: initial;
  }
}
