@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
}

.dropdownContent {
  padding-block: variables.$indent-xs;
}

.dropdownItem {
  text-align: start;

  .link {
    align-items: center;
    display: flex;
    height: 32px;
    line-height: variables.$indent-m;
    padding-inline: variables.$indent-s;
  }

  .linkText {
    @include mixins.m-regular-slh;
  }
}

.link:hover {
  @include mixins.transition(color, background-color);
  background-color: variables.$color-grey-98;
  border-radius: variables.$border-radius-m;
}

.container .icon {
  &:hover {
    background-color: variables.$color-grey-95;
    color: variables.$color-grey-50;
  }

  &.activeIcon {
    background-color: variables.$color-grey-95;
    color: variables.$color-grey-50;
  }
}

@include mixins.less-than-md {
  .dropdownItem {
    .link {
      height: 48px;
      line-height: variables.$indent-l;
    }

    .linkText {
      @include mixins.l-regular;
    }
  }
}
