@use 'styles/mixins';
@use 'styles/variables';

.loadingError {
  @include mixins.listItem(false);

  align-items: flex-start;
  color: variables.$color-grey-70;
  cursor: initial;
  flex-direction: column;
  margin: variables.$indent-xs 0;
  row-gap: variables.$indent-xxs;
}

.textBeforeButton {
  @include mixins.m-regular;
}
