@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  width: 100%;
}

.copyTextButton {
  @include mixins.m-medium;
  align-items: center;
  display: flex;
  width: 100%;
}

.withJustifyBetween {
  justify-content: space-between;
}

.withTruncateTextContainer {
  display: flex;
  width: 100%;
}

.ellipsisWrapper {
  flex: 1;
  margin-right: variables.$indent-xxs;
  min-width: 0;
  overflow: hidden;
}

.withTruncateText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  @include mixins.transition(color, opacity);

  display: flex;
  opacity: 0.5;

  &:hover,
  &:active {
    opacity: 1;
  }
}

.icon {
  color: variables.$color-grey-50;

  &:hover {
    color: variables.$color-grey-30;
  }

  &:active {
    color: variables.$color-grey-10;
  }
}
