@use 'styles/mixins';
@use 'styles/variables';

.loadingItem {
  @include mixins.listItem(false);

  color: variables.$color-grey-70;
  cursor: initial;
  margin-top: variables.$indent-xs;

  &:last-child {
    margin-bottom: variables.$indent-xs;
  }
}
