@use '../../styles/variables';
@use '../../styles/mixins';

.wrapper {
  border: 1px solid variables.$color-vavada-pink-40;
  border-radius: variables.$border-radius-l;
  padding: variables.$indent-m;
  @include mixins.error-block-shadow;
}

.errorWrapper:not(:last-of-type) {
  margin-bottom: variables.$indent-m;
}
