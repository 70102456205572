@use '../variables';
@use './common';

@mixin menuItem {
  @include common.transition(background-color);

  border-radius: variables.$border-radius-l;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding: variables.$indent-m;
  white-space: nowrap;
  width: 100%;

  &.active {
    background-color: variables.$color-vavada-pink-98;

    .iconContainer {
      color: variables.$color-vavada-pink-60;
    }
  }

  &:hover {
    background-color: variables.$color-grey-98;
  }

  &:active {
    background-color: variables.$color-grey-95;
  }

  &:not(:last-child) {
    margin-bottom: variables.$indent-xs;
  }
}
