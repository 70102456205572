@use 'styles/variables';
@use 'styles/mixins';

.mainContainer {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-xl;
  min-width: 304px;
}

.header {
  align-items: center;
  column-gap: variables.$indent-l;
  display: flex;
  justify-content: space-between;
  padding: variables.$indent-m;

  &.hasSubTitle {
    padding: variables.$indent-s variables.$indent-m;
  }
}

.hasHeaderSeparator .header {
  border-bottom: 1px solid variables.$color-grey-95;
}

.headerLeftBlock {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
}

.hasSubTitle .headerLeftBlock {
  margin: 0;
}

.contentAndFooterContainer {
  display: flex;
  flex-direction: column;
  padding-left: variables.$indent-m;
  padding-right: variables.$indent-m;

  &.smallBottomIndent {
    padding-bottom: variables.$indent-m;
  }

  &.largeBottomIndent {
    padding-bottom: variables.$indent-l;
  }
}

.hasHeaderSeparator .contentAndFooterContainer {
  padding-top: variables.$indent-m;
}

.title {
  @include mixins.l-large;
}

.subTitle {
  @include mixins.s-regular;

  color: variables.$color-grey-50;
}
