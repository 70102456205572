@use 'styles/variables';

.icon {
  color: variables.$color-grey-50;

  &:hover {
    color: variables.$color-grey-10;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  gap: variables.$indent-xs;
  justify-content: space-between;
  width: 100%;
}

.name {
  word-break: break-word;
}
