@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

$bottom-sheet-content-min-height: 192px;
$border-value: 1px solid variables.$color-grey-95;
$header-height: 65px;

.modalContainer {
  @include mixins.transition(variables.$transition-duration-300, transform);

  background-color: variables.$color-white;
  border-left: $border-value;
  border-right: $border-value;
  border-top: $border-value;
  border-top-left-radius: variables.$border-radius-l;
  border-top-right-radius: variables.$border-radius-l;
  bottom: 0;
  display: grid;
  grid-template-rows: 1fr;
  left: 0;
  max-height: 85dvh;
  min-height: $bottom-sheet-content-min-height;
  outline: none;
  padding: 0;
  position: absolute;
  transform: translateY(100%);
  width: 100%;

  &.afterOpen {
    transform: translateY(0);
  }

  &.beforeClose {
    transform: translateY(100%);
  }
}

.container {
  height: 100%;
  overflow: hidden;
  position: relative;

  .header {
    align-items: center;
    border-bottom: 1px solid variables.$color-grey-95;
    display: flex;
    justify-content: space-between;
    padding: variables.$indent-m;
  }

  .title {
    @include typography.l-medium;
    color: variables.$color-grey-10;
  }

  .closeButton {
    border-radius: variables.$border-radius-s;
  }
}

.content {
  height: calc(100% - $header-height);
  padding: variables.$indent-m;
}
