@use 'styles/variables';

.form {
  margin-bottom: variables.$indent-m;
}

.cardsBlock {
  display: flex;
  flex-wrap: wrap;
  gap: variables.$indent-m;
  margin-bottom: variables.$indent-l;
}

.card {
  flex-grow: 1;
}
