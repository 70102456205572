@use 'styles/variables';
@use 'styles/mixins';

.container {
  border-bottom: 1px solid variables.$color-grey-95;
  display: grid;
  grid-column: 1 / -1;
  justify-content: start;
  padding-block: variables.$indent-s;
}

.statusContainer {
  align-items: center;
  display: flex;
  gap: variables.$indent-xs;
  grid-column: span 2;
  left: 48px;
  position: sticky;
}

.loadingIcon {
  color: variables.$color-grey-70;
  margin-right: variables.$indent-xs;
}

.text {
  @include mixins.m-medium;

  color: variables.$color-grey-70;
}
