.input.input {
  grid-template-columns: 0 auto;

  &::after {
    content: '';
  }

  input {
    cursor: pointer;
    flex-grow: 1;
  }

  input:focus {
    cursor: text;
  }
}
