@use 'styles/mixins';
@use 'styles/variables';

.option {
  @include mixins.listItem;

  &:first-child {
    margin-top: variables.$indent-xs;
  }

  &:last-child {
    margin-bottom: variables.$indent-xs;
  }
}
