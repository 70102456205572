@use 'styles/variables';

.twoFactoWrapper {
  align-items: center;
  background-color: variables.$color-grey-98;
  border-radius: variables.$border-radius-l;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: variables.$indent-l;
  min-height: 266px;
  padding: variables.$indent-l variables.$indent-xl;
}

.imageWrapper {
  margin-bottom: variables.$indent-m;
}

.secretCodeWrapper {
  max-width: 100%;
  text-align: center;
  word-break: break-all;
}
