@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  height: 100%;
}

.title {
  @include mixins.m-medium;
  color: variables.$color-grey-10;
  line-height: variables.$line-height-medium;
}

.subTitle {
  @include mixins.s-regular;

  color: variables.$color-grey-50;
  line-height: variables.$line-height-small;
  margin-top: variables.$indent-xxs;
}

.iconContainer {
  align-items: center;
  display: flex;
  margin-right: variables.$indent-xs;
}

.textContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.rightAlign {
  justify-content: flex-end;

  .textContainer,
  .iconContainer {
    text-align: end;
  }
}
