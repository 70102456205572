@use 'styles/variables';
@use 'styles/mixins';

.overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: variables.$modal-overlay-z-index;

  &.afterOpen {
    animation: fadeIn 0.35s forwards;
  }

  &.beforeClose {
    animation: fadeOut 0.35s forwards;
  }
}

@keyframes fadeIn {
  from {
    background-color: rgba(variables.$color-black, 0);
  }

  to {
    background-color: rgba(variables.$color-black, 0.25);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba(variables.$color-black, 0.25);
  }

  to {
    background-color: rgba(variables.$color-black, 0);
  }
}
