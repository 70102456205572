@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

$sticky-headers-height: 132px;

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.title {
  @include typography.h1;

  color: variables.$color-grey-10;
  margin-bottom: 32px;
}

.wrapper .description {
  @include typography.m-regular;

  color: variables.$color-grey-10;
  margin-bottom: variables.$indent-l;
}

.wrapper .subsectionTitle {
  @include typography.l-medium;

  color: variables.$color-grey-10;
  margin-bottom: variables.$indent-m;
}

.wrapper .subsectionDescription {
  @include typography.m-regular;

  color: variables.$color-grey-10;
  margin-bottom: variables.$indent-s;
}

.wrapper .listTitle {
  @include typography.m-regular;

  color: variables.$color-grey-10;
  margin-bottom: 0;
}

.wrapper .listWrapper {
  margin-bottom: variables.$indent-l;
}

@include mixins.less-than-sm {
  .section,
  .subsection {
    scroll-margin-top: $sticky-headers-height;
  }
}
