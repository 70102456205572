/*
  These variables are for global z-index management.
  The aim is to limit available z-index ranges for specific blocks.
  E.g., for forms use indices 1-100, headers 101-200, tables 201-300.
  This ensures centralized control and avoids overlaps.
*/

$dropdown-z-index: 110;

$menu-z-index: 1000;
$modal-overlay-z-index: 1100;

$tooltip-z-index: 2000;
