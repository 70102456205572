@use 'styles/variables';
@use 'styles/mixins';

.postbacksContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  row-gap: variables.$indent-m;
}

.sticky {
  background-color: variables.$color-white;
  bottom: -1px;
  margin: 0 -#{variables.$indent-l} -#{variables.$indent-l};
  padding: variables.$indent-m variables.$indent-l;
  position: sticky;
}

.pinned {
  border-top: 1px solid variables.$color-grey-95;
}

@include mixins.less-than-sm {
  .sticky {
    margin: 0 -#{variables.$indent-m} -#{variables.$indent-m};
    padding: variables.$indent-xs variables.$indent-m;
  }
}
