@use 'styles/variables';
@use 'styles/mixins';

.buttonsWrapper {
  align-items: center;
  column-gap: variables.$indent-l;
  display: flex;
  justify-content: space-between;

  .tooltip {
    flex-grow: 1;
  }

  .addCardButton {
    flex-grow: 0;
    white-space: nowrap;
  }
}

.addCardButton .addButtonMobileText {
  display: none;
}

.sticky {
  background-color: variables.$color-white;
  bottom: -1px;
  margin: 0 -#{variables.$indent-l} -#{variables.$indent-l};
  padding: variables.$indent-m variables.$indent-l;
  position: sticky;
}

.pinned {
  border-top: 1px solid variables.$color-grey-95;
}

@include mixins.less-than-sm {
  .sticky {
    margin: 0 -#{variables.$indent-m} -#{variables.$indent-m};
    padding: variables.$indent-xs variables.$indent-m;

    .addCardButton {
      flex-grow: 1;
    }

    .addCardButton .addButtonMobileText {
      display: block;
    }

    .addCardButton .addButtonDesktopText {
      display: none;
    }
  }
}
