@use 'styles/variables';

.postbackButton {
  display: inline-flex;
}

.notConnected {
  color: variables.$color-grey-60;
}

.connected {
  color: variables.$color-green-30;
}

.disconnected,
.partiallyDisconnected {
  color: variables.$color-vavada-pink-40;
}
