@use 'styles/mixins';
@use 'styles/variables';

.noOptionMessage {
  @include mixins.listItem(false);

  color: variables.$color-grey-70;
  cursor: initial;
  margin: variables.$indent-xs 0;
}
