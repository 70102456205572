@use '../../../../styles/variables';
@use '../../../../styles/mixins';

.header {
  @include mixins.l-medium-slh;
  color: variables.$color-vavada-pink-40;
}

.text {
  @include mixins.m-regular;
  color: variables.$color-grey-50;
  margin-top: variables.$indent-xs;
}
