@use 'styles/variables';

$shift: 1px;
$min-container-width: 432px;
$min-part-width: $min-container-width / 2;

.mainContainer {
  container-type: inline-size;
  display: flex;
  flex-wrap: wrap;

  &:focus-within {
    z-index: 1;
  }
}

.selectContainer,
.inputContainer {
  min-width: $min-part-width;

  &:hover:not(.error) {
    z-index: 1;
  }

  &:focus-within:not(.error) {
    z-index: 2;
  }
}

.inputContainer {
  flex-grow: 1;
}

.mainContainer.inputDisabled {
  .selectContainer.error {
    z-index: 3;
  }
}

@container (min-width: #{$min-container-width}) {
  .mainContainer .input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .mainContainer .select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .selectContainer {
    min-width: $min-part-width;
  }

  .inputContainer {
    margin-left: -$shift;
  }
}

@container (max-width: #{$min-container-width}) {
  .mainContainer .input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mainContainer .select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .selectContainer {
    flex-grow: 1;
  }

  .mainContainer {
    margin-bottom: -$shift;
  }

  .mainContainer .inputContainer {
    margin-top: -$shift;
  }
}
