@use 'styles/mixins';
@use 'styles/variables';

.descriptionBlock {
  @include mixins.paragraph;
}

.contentContainer {
  margin-bottom: variables.$indent-l;
}

.copyTextContainer {
  margin-left: variables.$indent-xxs;
}
