@use 'styles/mixins';
@use 'styles/variables';

.datePickerContainer {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-l;
  box-shadow: 4px 4px 8px 0 rgba(0, 16, 74, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include mixins.less-than-md {
    border: 0;
    box-shadow: none;
    flex-direction: column;
  }
}

.container {
  display: flex;

  @include mixins.less-than-md {
    flex-direction: column;
  }
}

.datePicker {
  border-left: 1px solid variables.$color-grey-95;

  @include mixins.less-than-md {
    border-left: 0;
  }
}

.rangesContainer {
  display: flex;
  flex-direction: column;
  min-width: 304px;
  padding: 20px variables.$indent-s;

  @include mixins.less-than-md {
    border-bottom: 1px solid variables.$color-grey-90;
    flex-direction: row;
    overflow-x: auto;
  }
}

.range {
  margin-bottom: variables.$indent-xs;

  &:last-child {
    margin-bottom: 0;
  }

  &.active,
  &.active:hover {
    color: variables.$color-grey-10;
  }

  @include mixins.less-than-md {
    margin-bottom: 0;
    text-wrap: nowrap;
  }
}
