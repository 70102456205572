@use 'styles/variables';
@use 'styles/mixins';

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: variables.$indent-xl;
  row-gap: variables.$indent-xs;
}

.arrowIcon {
  color: variables.$color-grey-50;
  height: 16px;
  width: 16px;
}

@include mixins.less-than-md {
  .buttonsContainer {
    row-gap: variables.$indent-s;
  }
}
