@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

.list {
  @include typography.m-regular;

  color: variables.$color-grey-10;
}

.listItem {
  align-items: flex-start;
  display: flex;
  gap: variables.$indent-xs;
  margin-bottom: 0;
  margin-left: variables.$indent-m;

  &:has(.numeric) {
    gap: variables.$indent-m;
    margin-left: 0;
  }
}

.subItemList .listType {
  color: variables.$color-grey-40;
}
