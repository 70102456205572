@use 'styles/variables';
@use 'styles/mixins';

.menuItem {
  @include mixins.menuItem();
}

.iconContainer {
  margin-right: variables.$indent-m;
}
