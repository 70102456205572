@use '../variables';
@use './typography.scss';

@mixin tabsContainer {
  column-gap: variables.$indent-s;
  display: flex;
}

@mixin tab {
  color: variables.$color-grey-50;
  cursor: pointer;

  &:hover {
    color: variables.$color-grey-30;
  }

  &:active {
    color: variables.$color-grey-10;
  }

  &.active {
    color: variables.$color-grey-10;
    cursor: default;
  }

  &.small {
    @include typography.h3;
  }

  &.medium {
    @include typography.h2;
  }

  &.large {
    @include typography.h1;
  }
}
