@use 'styles/mixins';
@use 'styles/variables';

.loaderContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.withExcludedMenuWidth {
  left: calc(50% + variables.$menu-width);
  transform: translate(calc(-50% - variables.$menu-width), -50%);
}
