@use 'styles/variables';
@use 'styles/mixins';

.expandedContainer {
  display: flex;
  gap: variables.$indent-m;
  justify-content: space-between;
  padding: variables.$indent-m;
}

.divider {
  align-self: center;
  background-color: variables.$color-grey-10;
  display: none;
  height: variables.$indent-m;
  min-width: 1px;
  opacity: 0.1;
}

.filtersContainer,
.settingsContainer {
  display: flex;
}

@include mixins.less-than-md {
  .expandedContainer {
    overflow-x: auto;
  }
}

@include mixins.less-than-sm {
  .expandedContainer {
    justify-content: flex-start;
  }

  .divider {
    display: flex;
  }
}
