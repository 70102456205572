@use 'styles/variables';
@use 'styles/mixins';

.header {
  @include mixins.l-medium-slh;
  color: variables.$color-grey-10;
}

.text {
  @include mixins.m-regular;
  color: variables.$color-grey-50;
  margin-top: variables.$indent-xs;
}

.button {
  @include mixins.m-medium-slh;
  color: variables.$color-grey-40;
  margin-top: variables.$indent-m;
}
