@use '../../../../styles/variables';
@use '../../../../styles/mixins';

.tooltip {
  @include mixins.s-medium;
  border-radius: variables.$border-radius-m;
  box-sizing: border-box;

  max-width: 158px;
  padding: variables.$indent-s;

  z-index: variables.$tooltip-z-index;

  &.dark {
    background-color: variables.$color-black;
    color: variables.$color-white;
  }

  &.light {
    background-color: variables.$color-white;
    @include mixins.tooltip-box-shadow;
  }
}

.tooltip.light {
  .tooltipArrow {
    fill: variables.$color-white;
  }
}
