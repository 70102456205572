@use 'styles/mixins';
@use 'styles/variables';

$vertical-indent: 32px;

.menu {
  @include mixins.transition(
    variables.$transition-duration-300,
    width,
    box-shadow
  );
  @include mixins.l-medium;

  background-color: variables.$color-white;
  border-right: 1px solid variables.$color-grey-95;
  box-shadow: 0 0 0 0 #fff0;
  height: 100vh;
  left: 0;
  padding: $vertical-indent variables.$indent-m variables.$indent-l;
  position: fixed;
  top: 0;
  width: variables.$menu-width;
  z-index: variables.$menu-z-index;

  &:hover {
    @include mixins.menu-box-shadow;

    width: variables.$menu-expanded-width;
  }
}

.menuContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuButton {
  display: none;
}

.topMenuContainer {
  margin-bottom: auto;
}

.topImgWrapper {
  align-items: center;
  display: flex;
  height: variables.$indent-xl;
  margin-bottom: $vertical-indent;
  margin-right: 7px;
  overflow: hidden;
  padding-left: variables.$indent-m;
}

.topImg {
  flex-shrink: 0;
  width: 176px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@include mixins.less-than-md {
  .menu {
    height: fit-content;
    padding: 0;
    width: 100%;

    &:has(.drawerOpen) {
      height: 100%;
    }

    &:hover {
      box-shadow: none;
      width: 100%;
    }
  }

  .menuContainer {
    height: fit-content;

    &:has(.drawerOpen) {
      height: 100%;
    }
  }

  .contentWrapper {
    display: none;
    padding: variables.$indent-l variables.$indent-m;
  }

  .drawerOpen {
    display: initial;
    flex: 1;
    overflow: auto;
  }

  .menuButton {
    @include mixins.transition(background-color);

    align-items: center;
    border-radius: variables.$border-radius-m;
    cursor: pointer;
    display: flex;
    padding: variables.$indent-s;
  }

  .topImgWrapper {
    border-bottom: 1px solid variables.$color-grey-95;
    height: fit-content;
    margin: 0;
    padding: variables.$indent-m;
  }

  .topImg {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
