@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: variables.$indent-l;
}

.centralBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.topBlock {
  min-height: variables.$auth-layout-top-block-height;
}

.bottomBlock {
  min-height: 60px;
}

.topBlock {
  align-items: center;
  display: flex;
}

.bottomBlock {
  align-items: flex-end;
  display: flex;
}

@include mixins.less-than-sm {
  .container {
    row-gap: initial;
  }

  .centralBlock {
    justify-content: flex-start;
    margin-top: 60px;
  }

  .bottomBlock {
    margin-top: 32px;
  }
}
