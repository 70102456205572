@use 'styles/variables';
@use 'styles/mixins';

$min-card-width: 300px;

.topPanelWrapper {
  display: grid;
  grid-gap: variables.$indent-m;
  grid-template-columns: repeat(auto-fit, minmax($min-card-width, 1fr));
  margin-bottom: variables.$indent-xl;

  .rewardCardsWrapper {
    display: contents;
  }

  .balanceCardWrapper {
    background-color: variables.$color-white;
    grid-column: span 2;
  }

  .nextRewardCardWrapper {
    background-color: variables.$color-white;
    grid-column: span 1;
  }

  .withdrawRewardCardWrapper {
    background-color: variables.$color-white;
    grid-column: span 1;
  }
}

@include mixins.less-than-sm {
  .topPanelWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: variables.$indent-l;

    .rewardCardsWrapper {
      display: flex;
      gap: variables.$indent-m;
      margin-right: -#{variables.$indent-m};
      overflow-x: auto;
      padding-right: variables.$indent-m;
      white-space: nowrap;
    }

    .nextRewardCardWrapper {
      min-width: $min-card-width;
    }

    .withdrawRewardCardWrapper {
      min-width: $min-card-width;
    }
  }
}
