@use 'styles/variables';
@use 'styles/mixins';

%active-style {
  background-color: variables.$color-purple-90;
  color: variables.$color-purple-50;
}

.active,
.active:not(:disabled):hover {
  @extend %active-style;
}

@include mixins.less-than-sm {
  .buttonText {
    display: none;
  }
}
