@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

$nav-bar-height: 73px;

.wrapper {
  display: flex;
  flex: 0 0 35%;
  flex-direction: column;
  gap: 32px;
  position: sticky;
  top: 0;
}

.title {
  @include typography.l-medium;

  margin-bottom: 20px;
}

.link {
  @include typography.m-medium;

  margin-bottom: variables.$indent-s;

  &:last-child {
    margin-bottom: 0;
  }
}

@include mixins.less-than-md {
  .wrapper {
    flex: 1;
    position: relative;
  }
}

@include mixins.less-than-sm {
  .wrapper {
    position: sticky;
  }
}
