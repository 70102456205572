@use 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: variables.$indent-xxs;
  padding-block: variables.$indent-xs;
}

.checkbox {
  padding: variables.$indent-xs;
}

.bottomSheetContent,
.popUpContent {
  padding: 0 variables.$indent-xs;
}
