@use '../variables';
@use './input';
@use './common';
@use './typography';

@mixin dropdownContainer {
  @include input.inputContainer;
  @include common.select-box-shadow;

  border-color: variables.$color-grey-95;
  cursor: default;
  overflow-y: auto;
  padding: 0 variables.$indent-xs;

  &:hover {
    border-color: variables.$color-grey-95;
  }
}
