@use 'styles/variables';

.form {
  width: 100%;
}

.settingsWrapper {
  align-items: center;
  display: flex;
  gap: variables.$indent-m;
}
