@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}

.content {
  align-items: center;
  display: flex;
  flex: 0 0 320px;
  flex-direction: column;
  gap: variables.$indent-xxs;
}

.description {
  @include mixins.m-regular;

  color: variables.$color-grey-70;
}
