@use 'styles/variables';
@use 'styles/mixins';

.layout {
  background-color: variables.$color-special-base-bg;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 32px variables.$indent-xl 32px variables.$menu-width +
    variables.$indent-xl;

  @include mixins.less-than-md {
    padding: 100px variables.$indent-m 32px;
  }
}
