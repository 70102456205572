@use 'styles/variables';
@use 'styles/mixins';

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.Typewriter) {
  display: inline;
}

.logo {
  min-height: variables.$auth-layout-top-block-height;
}

.greetingHeading {
  color: variables.$color-grey-10;
  font-size: 67px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: normal;
  line-height: 1.1em;
  text-transform: uppercase;

  @media (max-width: 1090px) {
    font-size: 6.09vw;
  }

  @media (max-width: 1090px) and (max-height: 620px) {
    font-size: 5.9vw;
  }
}

.typingContainer {
  color: variables.$color-vavada-pink-60;
  position: relative;
}

.imgContainer {
  background-image: url('../../../../../../assets/images/vavada.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 58%;
  margin-bottom: variables.$indent-xxxl;
  position: relative;
}

.cursor {
  animation: blink-animation variables.$animation-duration infinite;
  background-color: variables.$color-vavada-pink-60;
  color: transparent;
  display: inline-block;
  height: 1.02em;
  left: 2px;
  position: relative;
  top: 2px;
  width: 3px;
}

.textContainer {
  align-items: center;
  display: flex;
  flex-grow: 1;
}
