@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  column-gap: variables.$indent-m;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: variables.$indent-m;
  row-gap: variables.$indent-xs;
}
