@use '../../../../styles/variables';

.formBlockWrapper {
  display: flex;
  flex-direction: column;
}

.largeIndentBetweenRows {
  row-gap: variables.$indent-m;
}

.smallIndentBetweenRows {
  row-gap: variables.$indent-s;
}

.largeMarginBottom {
  margin-bottom: variables.$indent-xl;
}

.mediumMarginBottom {
  margin-bottom: 32px;
}

.smallMarginBottom {
  margin-bottom: variables.$indent-l;
}
