@use '../../../../styles/variables';
@use '../../../../styles/mixins';

$textarea-height: 108px;

.container {
  min-height: $textarea-height;

  &:not(.autoHeight) {
    height: $textarea-height;
  }
}

.textarea {
  @include mixins.input;
  height: calc(100% - variables.$indent-m);
  overflow-y: auto;
  resize: none;
}
