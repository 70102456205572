@use 'styles/variables';

.success {
  color: variables.$color-green-30;
}

.warning {
  color: variables.$color-orange-60;
}

.error {
  color: variables.$color-vavada-pink-40;
}

.pending {
  color: variables.$color-orange-60;
}

.inProgress {
  color: variables.$color-blue-60;
}
