@use '../variables';
@use '../mixins/breakpoints';

@mixin h1 {
  font-size: variables.$header-font-size-large;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: variables.$header-line-height-large;
}

@mixin h2 {
  font-size: variables.$header-font-size-medium;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: variables.$header-line-height-medium;
}

@mixin h3 {
  font-size: variables.$header-font-size-small;
  font-weight: 500;
  letter-spacing: -0.005em;
  line-height: variables.$header-line-height-small;
}

@mixin spec-header-large {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 48px;
  margin-bottom: variables.$indent-s;

  @include breakpoints.less-than-sm {
    font-size: 30px;
    line-height: 40px;
  }
}

@mixin l-large {
  font-size: variables.$font-size-extra-large;
  font-weight: 500;
  line-height: variables.$line-height-large;
}

@mixin l-medium {
  font-size: variables.$font-size-large;
  font-weight: 500;
  line-height: variables.$line-height-large;
}

@mixin l-medium-slh {
  font-size: variables.$font-size-large;
  font-weight: 500;
  line-height: variables.$line-height-small;
}

@mixin l-regular {
  font-size: variables.$font-size-large;
  font-weight: 400;
  line-height: variables.$line-height-large;
}

@mixin l-regular-slh {
  font-size: variables.$font-size-large;
  font-weight: 400;
  line-height: variables.$line-height-small;
}

@mixin m-semibold {
  font-size: variables.$font-size-medium;
  font-weight: 600;
  line-height: variables.$line-height-medium;
}

@mixin m-medium {
  font-size: variables.$font-size-medium;
  font-weight: 500;
  line-height: variables.$line-height-medium;
}

@mixin m-medium-slh {
  font-size: variables.$font-size-medium;
  font-weight: 500;
  line-height: variables.$line-height-small;
}

@mixin m-medium-slh-mono {
  font-size: variables.$font-size-medium;
  font-variant-numeric: lining-nums tabular-nums;
  font-weight: 500;
  line-height: variables.$line-height-small;
}

@mixin m-regular {
  font-size: variables.$font-size-medium;
  font-weight: 400;
  line-height: variables.$line-height-medium;
}

@mixin m-regular-slh {
  font-size: variables.$font-size-medium;
  font-weight: 400;
  line-height: variables.$line-height-small;
}

@mixin m-regular-slh-mono {
  font-size: variables.$font-size-medium;
  font-variant-numeric: lining-nums tabular-nums;
  font-weight: 400;
  line-height: variables.$line-height-small;
}

@mixin s-semibold {
  font-size: variables.$font-size-small;
  font-weight: 600;
  line-height: variables.$line-height-small;
}

@mixin s-medium {
  font-size: variables.$font-size-small;
  font-weight: 500;
  line-height: variables.$line-height-small;
}

@mixin s-regular {
  font-size: variables.$font-size-small;
  font-weight: 400;
  line-height: variables.$line-height-small;
}

@mixin xs-semibold {
  font-size: variables.$font-size-extra-small;
  font-weight: 600;
  line-height: variables.$line-height-small;
}

@mixin xs-medium {
  font-size: variables.$font-size-extra-small;
  font-weight: 500;
  line-height: variables.$line-height-medium;
}
