@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

$pop-up-width: 640px;
$pop-up-width-md: 430px;
$pop-up-width-sm: 312px;
$pop-up-max-height: 640px;
$pop-up-min-height: 196px;
$header-height: 65px;

.modalContainer {
  @include mixins.transition(variables.$transition-duration-400, transform);

  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-l;
  display: flex;
  left: 50%;
  max-height: $pop-up-max-height;
  min-height: $pop-up-min-height;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 50vh);
  width: $pop-up-width;

  &.afterOpen {
    transform: translate(-50%, -50%);
  }

  &.beforeClose {
    transform: translate(-50%, 50vh);
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  .header {
    align-items: center;
    border-bottom: 1px solid variables.$color-grey-95;
    display: flex;
    justify-content: space-between;
    padding: variables.$indent-m;
  }

  .title {
    @include typography.l-medium;
    color: variables.$color-grey-10;
  }

  .closeButton {
    border-radius: variables.$border-radius-s;
  }
}

.content {
  flex-grow: 1;
  max-height: calc(100% - $header-height);
  padding: variables.$indent-m;
}

@include mixins.less-than-md {
  .modalContainer {
    width: $pop-up-width-md;
  }
}

@include mixins.less-than-sm {
  .modalContainer {
    width: $pop-up-width-sm;
  }
}
