@use 'styles/variables';
@use 'styles/mixins';

.textBlock {
  margin-bottom: variables.$indent-l;
}

.logo {
  display: none;
}

@include mixins.less-than-md {
  .logo {
    display: flex;
    width: 100%;
  }
}
