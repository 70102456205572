$min-app-width: 360px;

$indent-xxs: 4px;
$indent-xs: 8px;
$indent-s: 12px;
$indent-m: 16px;
$indent-l: 24px;
$indent-xl: 40px;
$indent-xxl: 60px;
$indent-xxxl: 80px;

$border-radius-xl: 20px;
$border-radius-l: 16px;
$border-radius-m: 12px;
$border-radius-s: 8px;
$border-radius-xs: 4px;

$transition-duration-100: 100ms;
$transition-duration-300: 300ms;
$transition-duration-400: 400ms;

$animation-duration: 1.5s;

$menu-width: 88px;
$menu-expanded-width: 282px;
$auth-layout-top-block-height: 40px;
