@use '../variables';
@use '../mixins/typography';
@use '../mixins/common';
@use '../mixins/breakpoints.scss';

@mixin disabledInput {
  background-color: variables.$color-grey-98;
  border-color: variables.$color-grey-90;
  color: variables.$color-grey-70;
  cursor: default;
}

@mixin disabledSearchInput {
  color: variables.$color-grey-70;
  cursor: default;
  opacity: 0.5;
}

@mixin inputContainer {
  @include common.transition(background-color, border-color);

  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-90;
  border-radius: variables.$border-radius-l;
  box-sizing: border-box;
  color: variables.$color-grey-30;
  padding: variables.$indent-xs variables.$indent-s;

  &:hover {
    border-color: variables.$color-grey-80;
  }

  &:focus-within {
    border-color: variables.$color-grey-60;
  }

  &.disabled {
    @include disabledInput;
  }

  &.isError {
    border-color: variables.$color-vavada-pink-30;
    color: variables.$color-vavada-pink-40;
  }

  &.isEmpty {
    color: variables.$color-grey-50;
  }
}

@mixin input {
  @include typography.l-regular;
  color: variables.$color-grey-10;
  min-height: variables.$line-height-large;
  outline: none;
  width: 100%;

  &:focus:not(.error) {
    outline: none;
  }

  &:disabled {
    color: variables.$color-grey-70;
  }

  &::placeholder {
    color: transparent;
  }

  // remove arrows from number input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }
}

@mixin inputSearchContainer {
  @include common.transition(background-color, border-color);
  align-items: center;
  background-color: variables.$color-white;
  box-sizing: border-box;
  color: variables.$color-grey-60;
  display: flex;
  flex: 1;
  height: 64px;
  margin: 0;
  padding: variables.$indent-m;
  position: relative;

  &.disabled {
    @include disabledSearchInput;
  }

  @include breakpoints.less-than-sm {
    height: 56px;
    padding: variables.$indent-s variables.$indent-m;
  }
}

@mixin inputSearch {
  @include typography.m-medium-slh;

  color: variables.$color-grey-10;
  min-height: variables.$line-height-large;
  outline: none;
  padding-inline: 32px;
  width: 100%;

  &:disabled {
    color: variables.$color-grey-70;
  }

  &::placeholder {
    @include typography.m-medium-slh;

    color: variables.$color-grey-70;
  }
}
