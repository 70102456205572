@use 'styles/variables';
@use 'styles/mixins';

.modalContent {
  left: 50%;
  opacity: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);

  &:focus {
    outline: none;
  }

  &.afterOpen {
    opacity: 1;
    transition: opacity 350ms;
  }

  &.beforeClose {
    opacity: 0;
    transition: none;
  }
}

.card {
  width: min-content;

  .content {
    @include mixins.m-regular;

    color: variables.$color-grey-50;
    margin-bottom: variables.$indent-l;
  }

  .buttonsBlock {
    display: flex;
    gap: variables.$indent-m;
  }
}

.button {
  white-space: nowrap;
}

@include mixins.less-than-md {
  .buttonsBlock {
    flex-direction: column;
  }
}
