@use 'styles/variables';

.selectsContainer {
  column-gap: variables.$indent-xs;
  display: flex;
}

.leftSelect {
  flex-grow: 1;
}

.rightSelect {
  width: 150px;
}
