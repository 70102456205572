@use 'styles/variables';
@use 'styles/mixins';

.container {
  align-items: center;
  column-gap: variables.$indent-xxs;
  display: flex;
}

.large {
  @include mixins.l-regular;
}

.small,
.medium {
  @include mixins.m-regular;
}
