@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  gap: variables.$indent-xs;
  justify-content: space-between;
}

.tabs {
  @include mixins.tabsContainer;
}

.tab {
  @include mixins.tab;
}

@include mixins.less-than-sm {
  .container {
    flex-direction: column;
  }
}
