@use 'styles/variables';

.contentContainer {
  .description {
    margin-bottom: variables.$indent-l;
  }
}

.submitButton {
  margin-bottom: variables.$indent-m;
}
