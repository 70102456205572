/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */

@use 'styles/variables';
@use 'styles/mixins';

@import 'react-datepicker/dist/react-datepicker';

$pop-up-width: 430px;

.datePickerContainer {
  background-color: variables.$color-white;
  z-index: 2;

  :global(.react-datepicker__week) {
    margin-bottom: variables.$indent-xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.react-datepicker__day-names) {
    margin: 0 variables.$indent-s;
  }

  :global(.react-datepicker__week),
  :global(.react-datepicker__day-names) {
    display: flex;

    @include mixins.less-than-md {
      justify-content: space-between;
    }
  }

  :global(.react-datepicker) {
    border: 0;
    border-radius: variables.$indent-xs;
  }

  :global(.react-datepicker__year-text--selected),
  :global(.react-datepicker__month-text--selected),
  :global(.react-datepicker__month-text--keyboard-selected),
  :global(.react-datepicker__day--keyboard-selected),
  :global(.react-datepicker__year-text--in-range),
  :global(.react-datepicker__month-text--in-range) {
    background-color: transparent;
    color: initial;
  }

  :global(.react-datepicker__day--today),
  :global(.react-datepicker__month-text--today),
  :global(.react-datepicker__year-text--today) {
    color: variables.$color-vavada-pink-60;
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__month-text),
  :global(.react-datepicker__year-text) {
    align-items: center;
    border-radius: variables.$border-radius-m;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    width: 40px;

    &:hover {
      background-color: variables.$color-grey-95;
      color: variables.$color-black;
    }

    @include mixins.less-than-md {
      width: 58px;
    }
  }

  :global(.react-datepicker__month .react-datepicker__month-text) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 90px;

    &:last-child {
      margin-right: 0;
    }
  }

  :global(.react-datepicker__year .react-datepicker__year-text) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 variables.$indent-xs;
    min-height: 40px;
    width: 272px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mixins.less-than-md {
      width: 100%;
    }
  }

  :global(.react-datepicker__year-wrapper) {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 320px;
    max-width: initial;
    overflow-x: hidden;
    overflow-y: auto;
  }

  :global(.react-datepicker__month-wrapper) {
    display: flex;
    margin-bottom: variables.$indent-l;

    &:last-child {
      margin-bottom: 0;
    }

    @include mixins.less-than-md {
      justify-content: space-between;
    }
  }

  :global(.react-datepicker__month) {
    margin: 0 variables.$indent-s variables.$indent-s;
  }

  :global(.react-datepicker__day-name) {
    align-items: center;
    color: variables.$color-grey-50;
    display: flex;
    font-size: variables.$font-size-medium;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    width: 40px;

    @include mixins.less-than-md {
      width: 58px;
    }
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0;
    margin-bottom: variables.$indent-xs;
    padding: 0;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__day--outside-month) {
    color: variables.$color-grey-50;
  }

  :global(.react-datepicker__day--selected),
  :global(
      .react-datepicker__day--in-selecting-range:not(
          .react-datepicker__day--in-range
        )
    ),
  :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) {
    background-color: variables.$color-grey-10;
    border-radius: variables.$border-radius-m;
    color: variables.$color-white;

    &:hover {
      background-color: variables.$color-grey-10;
      color: variables.$color-white;
    }
  }

  :global(
      .react-datepicker__day--range-start.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-end
        )
    ) {
    $in-range-day-width-sm: calc(((100vw - 32px) / 7) - 58px);
    $in-range-day-width-md: calc((($pop-up-width - 32px) / 7) - 58px);

    @include mixins.less-than-md {
      box-shadow: calc(($in-range-day-width-md / 2) + 8px) 0 0 0
        variables.$color-grey-98;
    }

    @include mixins.less-than-sm {
      box-shadow: calc(($in-range-day-width-sm / 2) + 8px) 0 0 0
        variables.$color-grey-98;
    }
  }

  :global(
      .react-datepicker__day--range-end.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-start
        )
    ) {
    $in-range-day-width-sm: calc(((100vw - 32px) / 7) - 58px);
    $in-range-day-width-md: calc((($pop-up-width - 32px) / 7) - 58px);

    @include mixins.less-than-md {
      box-shadow: calc((-1 * $in-range-day-width-md / 2) - 8px) 0 0 0
        variables.$color-grey-98;
    }

    @include mixins.less-than-sm {
      box-shadow: calc((-1 * $in-range-day-width-sm / 2) - 8px) 0 0 0
        variables.$color-grey-98;
    }
  }

  :global(.react-datepicker__day--range-start.react-datepicker__day--in-range),
  :global(
      .react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range
    ),
  :global(
      .react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range
    ),
  :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) {
    position: relative;
    z-index: 10;
  }

  :global(
      .react-datepicker__day--in-range:not(
          .react-datepicker__day--range-start,
          .react-datepicker__day--range-end
        )
    ),
  :global(
      .react-datepicker__day--in-selecting-range:not(
          .react-datepicker__day--selecting-range-start,
          .react-datepicker__day--in-range
        )
    ) {
    background-color: variables.$color-grey-98;
    border-radius: 0;
    color: variables.$color-black;
  }

  :global(.react-datepicker__year-text--disabled),
  :global(.react-datepicker__month-text--disabled),
  :global(.react-datepicker__day--disabled) {
    color: variables.$color-grey-70;
    pointer-events: none;
  }

  :global(.react-datepicker__aria-live) {
    display: none;
  }

  :global(
      .react-datepicker__day.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-start,
          .react-datepicker__day--range-end
        ):first-child
    ),
  :global(
      .react-datepicker__day--in-selecting-range:not(
          .react-datepicker__day--selecting-range-start,
          .react-datepicker__day--selecting-range-end
        ):first-child
    ) {
    border-radius: variables.$border-radius-m 0 0 variables.$border-radius-m;
  }

  :global(
      .react-datepicker__day.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-start,
          .react-datepicker__day--range-end
        ):last-child
    ),
  :global(
      .react-datepicker__day--in-selecting-range:not(
          .react-datepicker__day--selecting-range-start,
          .react-datepicker__day--selecting-range-end,
          .react-datepicker__day--in-range
        ):last-child
    ) {
    border-radius: 0 variables.$border-radius-m variables.$border-radius-m 0;
  }

  :global(
      .react-datepicker__day.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-start,
          .react-datepicker__day--range-end
        )
    ),
  :global(
      .react-datepicker__day.react-datepicker__day--in-selecting-range:not(
          .react-datepicker__day--selecting-range-start,
          .react-datepicker__day--selecting-range-end
        )
    ) {
    position: relative;
    $in-range-day-width-sm: calc(((100vw - 32px) / 7) - 100%);
    $in-range-day-width-md: calc((($pop-up-width - 32px) / 7) - 40px);

    &::before {
      background-color: variables.$color-grey-98;
      content: '';
      height: 100%;
      left: -11px;
      pointer-events: none;
      position: absolute;
      width: 11px;
      z-index: 1;

      @include mixins.less-than-md {
        left: calc((-1 * $in-range-day-width-md / 2) - 2px);
        width: calc(($in-range-day-width-md / 2) + 2px);
      }

      @include mixins.less-than-sm {
        left: calc((-1 * $in-range-day-width-sm / 2) - 2px);
        width: calc(($in-range-day-width-sm / 2) + 2px);
      }
    }

    &::after {
      background-color: variables.$color-grey-98;
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      right: -11px;
      width: 11px;
      z-index: 1;

      @include mixins.less-than-md {
        right: calc((-1 * $in-range-day-width-md / 2) - 2px);
        width: calc(($in-range-day-width-md / 2) + 2px);
      }

      @include mixins.less-than-sm {
        right: calc((-1 * $in-range-day-width-sm / 2) - 2px);
        width: calc(($in-range-day-width-sm / 2) + 2px);
      }
    }
  }

  :global(.react-datepicker__day:last-child)::after,
  :global(.react-datepicker__day:first-child)::before {
    display: none;
  }
}

:global(
    .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end
  ) {
  border-radius: variables.$border-radius-m;
}

/* stylelint-enable max-nesting-depth */
/* stylelint-enable selector-class-pattern */
