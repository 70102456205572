@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.tabsContainer;
}

.link {
  @include mixins.tab;
}
