@use 'styles/variables';
@use 'styles/mixins';

.datePickerWrapper {
  position: relative;
}

.dropdown {
  @include mixins.dropdownContainer;

  position: absolute;
  top: 100%;
  transform: translateY(4px);
}

.inputWrapper {
  background-color: variables.$color-special-base-bg;
}
