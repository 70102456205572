@use 'styles/variables';
@use 'styles/mixins';

@mixin reduced-label-text {
  @include mixins.s-regular;
  top: variables.$indent-xs;
}

.label {
  display: block;
  position: relative;
  text-align: left;

  &.hasMinWidth {
    min-width: 192px;
  }
}

.label:has(input:-webkit-autofill),
.label:has(textarea:-webkit-autofill) {
  .formItemContainer .labelText {
    @include reduced-label-text;
  }
}

.formItemContainer {
  @include mixins.inputContainer;
  position: relative;

  &:focus-within .overlay {
    display: block;
  }

  &:focus-within.reduceLabelOnFocus .labelText,
  &:not(.isEmpty) .labelText {
    @include reduced-label-text;
  }

  &:focus-within.isEmpty .labelText {
    color: variables.$color-grey-30;
  }

  &.cursorText {
    cursor: text;
  }

  &.cursorPointer {
    cursor: pointer;
  }
}

.children {
  column-gap: variables.$indent-xs;
  display: flex;
  height: 100%;
  margin-top: variables.$indent-m;
  position: relative;
}

.withNoLabel {
  margin-top: 0;
}

.overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

$indent-if-has-icon: variables.$indent-s + variables.$indent-xs +
  variables.$icon-l;

.labelText {
  @include mixins.transition(color, font-size, top);
  @include mixins.l-regular;

  max-width: calc(100% - variables.$indent-s * 2);
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: variables.$indent-m;
  user-select: none;
  white-space: nowrap;

  &.hasIcon {
    width: calc(100% - $indent-if-has-icon - variables.$indent-s);
  }

  &.hasLeftIcon {
    left: $indent-if-has-icon;
  }

  &.hasTwoIcons {
    width: calc(100% - $indent-if-has-icon * 2);
  }
}
