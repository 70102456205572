@use 'styles/variables';
@use 'styles/mixins';

.iconContainer {
  display: inline-flex;
}

.icon {
  vertical-align: initial;
}

.hasDefaultColor {
  color: variables.$color-grey-30;
}

.extraLarge {
  height: variables.$icon-xl;
  width: variables.$icon-xl;
}

.large {
  height: variables.$icon-l;
  width: variables.$icon-l;
}

.medium {
  height: variables.$icon-m;
  width: variables.$icon-m;
}

.small {
  height: variables.$icon-s;
  width: variables.$icon-s;
}

.spin {
  @include mixins.spinAnimation;
}
