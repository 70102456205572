@use 'styles/mixins';
@use 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: variables.$indent-l;

  .headline {
    @include mixins.l-medium;
  }
}

@include mixins.less-than-sm {
  .container {
    gap: 20px;
  }
}
