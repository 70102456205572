@use 'styles/variables';
@use 'styles/mixins';

.expandButton {
  padding: variables.$indent-xs;

  svg {
    height: variables.$icon-s;
    width: variables.$icon-s;
  }
}

.icon {
  @include mixins.transition(rotate);

  &.activeIcon {
    rotate: 90deg;
  }
}
