@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: grid;

  grid-template-columns: 1fr 2fr;

  &.equalColumnsWidth {
    grid-template-columns: 1fr 1fr;
  }
}

.large {
  @include mixins.l-regular;

  column-gap: variables.$indent-xs;
  row-gap: variables.$indent-l;
}

.small {
  @include mixins.m-regular;

  gap: variables.$indent-xs;
}

.title {
  color: variables.$color-grey-50;
}

.value {
  hyphens: auto;
  overflow: hidden;
}

@include mixins.less-than-md {
  .container.stacked {
    grid-template-columns: 1fr;
    row-gap: 0;

    .gridElem:nth-child(odd) {
      margin-bottom: variables.$indent-xs;
    }

    .gridElem:nth-child(even):not(:last-child) {
      margin-bottom: variables.$indent-l;
    }
  }
}

@include mixins.less-than-sm {
  .container.stacked {
    .gridElem:nth-child(even):not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
