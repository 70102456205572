@use 'styles/variables';
@use 'styles/mixins';

.modal {
  .whereToWithdrawInfoText {
    @include mixins.l-regular;

    margin-bottom: variables.$indent-l;
  }
}

.withdrawalMethod {
  margin-bottom: variables.$indent-s;
  width: 100%;
}

.rightBlockWrapper {
  align-items: center;
  column-gap: variables.$indent-xs;
  display: flex;
}

.arrowIcon {
  color: variables.$color-grey-50;
}
