$color-blue-10: #03143e;
$color-blue-20: #07287d;
$color-blue-30: #0a3cbb;
$color-blue-40: #1453f3;
$color-blue-50: #3b6ff5;
$color-blue-60: #5985f6;
$color-blue-70: #759af8;
$color-blue-80: #9eb8fa;
$color-blue-90: #baccfb;
$color-blue-95: #e3ebfe;
$color-blue-98: #f1f5fe;

$color-vavada-pink-10: #330009;
$color-vavada-pink-20: #601;
$color-vavada-pink-30: #99001a;
$color-vavada-pink-40: #c02;
$color-vavada-pink-50: #ff002b;
$color-vavada-pink-60: #f35;
$color-vavada-pink-70: #ff6680;
$color-vavada-pink-80: #f9a;
$color-vavada-pink-90: #ffccd5;
$color-vavada-pink-95: #ffe5ea;
$color-vavada-pink-98: #fff5f7;

$color-green-10: #01310f;
$color-green-20: #03631e;
$color-green-30: #04952d;
$color-green-40: #05cd3e;
$color-green-50: #0bf94e;
$color-green-60: #42fa76;
$color-green-70: #74fc9a;
$color-green-80: #9bfdb6;
$color-green-90: #cdfedb;
$color-green-95: #e6feed;
$color-green-98: #f5fff8;

$color-yellow-10: #3d3500;
$color-yellow-20: #706100;
$color-yellow-30: #9e8900;
$color-yellow-40: #dbbe00;
$color-yellow-50: #fadb14;
$color-yellow-60: #ffe53d;
$color-yellow-70: #ffea66;
$color-yellow-80: #fff199;
$color-yellow-90: #fff8cc;
$color-yellow-95: #fffce5;
$color-yellow-98: #fffef5;

$color-gold-10: #462f01;
$color-gold-20: #785102;
$color-gold-30: #aa7204;
$color-gold-40: #d79005;
$color-gold-50: #faad14;
$color-gold-60: #fbbf46;
$color-gold-70: #fccc6e;
$color-gold-80: #fddc9b;
$color-gold-90: #feeecd;
$color-gold-95: #fef6e6;
$color-gold-98: #fffbf5;

$color-orange-10: #412202;
$color-orange-20: #733c03;
$color-orange-30: #a45604;
$color-orange-40: #d77105;
$color-orange-50: #fa8c16;
$color-orange-60: #fba141;
$color-orange-70: #fcba73;
$color-orange-80: #fdd0a0;
$color-orange-90: #fee6cd;
$color-orange-95: #fef3e6;
$color-orange-98: #fffaf5;

$color-grey-0: #000;
$color-grey-10: #15161e;
$color-grey-20: #262836;
$color-grey-30: #3b3e54;
$color-grey-40: #505472;
$color-grey-50: #656a90;
$color-grey-60: #8186a7;
$color-grey-70: #9fa3bc;
$color-grey-80: #c3c5d5;
$color-grey-90: #dedfe8;
$color-grey-95: #edeef2;
$color-grey-98: #f6f6f9;
$color-grey-100: #fff;

$color-purple-10: #1c0a33;
$color-purple-20: #2f1155;
$color-purple-30: #471980;
$color-purple-40: #5f21ab;
$color-purple-50: #7729d6;
$color-purple-60: #9254de;
$color-purple-70: #ad7fe6;
$color-purple-80: #c8aaee;
$color-purple-90: #e3d4f6;
$color-purple-95: #f1eafb;
$color-purple-98: #f9f6fd;

$color-magenta-10: #35031d;
$color-magenta-20: #660537;
$color-magenta-30: #970751;
$color-magenta-40: #c70a6c;
$color-magenta-50: #f41688;
$color-magenta-60: #f646a1;
$color-magenta-70: #f86db5;
$color-magenta-80: #faa3d0;
$color-magenta-90: #fdcee6;
$color-magenta-95: #fee7f3;
$color-magenta-98: #fff5fa;

$color-white: $color-grey-100;
$color-black: $color-grey-0;

$color-special-base-bg: #f9f9fb;
