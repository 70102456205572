@use 'styles/variables';
@use 'styles/mixins';

$border-line-width: 2px;
$outline-width: 9px;
$checked-dot-size: 10px;
$radio-size: 24px;

.pseudoRadio {
  @include mixins.transition(outline-width, background, border);
  align-items: center;
  background: transparent;
  border: $border-line-width solid variables.$color-grey-10;
  border-radius: 50%;
  display: flex;
  height: $radio-size;
  justify-content: center;
  outline: 0 solid variables.$color-grey-95;
  width: $radio-size;

  &::after {
    @include mixins.transition(width, height);
    background: variables.$color-grey-10;
    border-radius: 50%;
    content: '';
    display: block;
    height: 0;
    width: 0;
  }
}

.disabledPseudoRadio {
  border-color: variables.$color-grey-70;
}

.radio:checked + .pseudoRadio {
  border-color: variables.$color-grey-10;

  &::after {
    height: $checked-dot-size;
    width: $checked-dot-size;
  }
}

.radio:checked + .disabledPseudoRadio {
  border-color: variables.$color-grey-70;

  &::after {
    background: variables.$color-grey-70;
  }
}

.label {
  align-items: center;
  display: flex;

  &.disabledLabel {
    cursor: default;
  }

  &:hover .pseudoRadio {
    background: variables.$color-grey-95;
    outline: $outline-width solid variables.$color-grey-95;
  }

  &:active .pseudoRadio {
    background: variables.$color-grey-90;
    outline: $outline-width solid variables.$color-grey-90;
  }

  &:hover .disabledPseudoRadio,
  &:active .disabledPseudoRadio {
    background: none;
    outline: none;
  }
}

.labelText {
  @include mixins.m-medium;
  color: variables.$color-grey-30;
  margin-left: variables.$indent-xs;

  .disabledLabel & {
    color: variables.$color-grey-70;
  }
}
