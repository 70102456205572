@use 'styles/mixins';
@use 'styles/variables';

.enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.enterActive {
  @include mixins.transition(
    variables.$transition-duration-400,
    max-height,
    opacity
  );

  max-height: 500px;
  opacity: 1;
  overflow: auto;
}

.exit {
  max-height: 500px;
  opacity: 1;
}

.exitActive {
  @include mixins.transition(
    variables.$transition-duration-400,
    max-height,
    opacity
  );

  max-height: 0;
  opacity: 0;
}

.container {
  display: flex;
}

.tableBody {
  @include mixins.m-regular;
  display: grid;
  flex-basis: 100%;
  grid-auto-rows: min-content;
  position: relative;

  .infinityScrollTrigger {
    bottom: 0;
    height: 1px;
    position: absolute;
  }
}

.loadingContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.loadingIcon {
  color: variables.$color-grey-70;
  margin-right: variables.$indent-xs;
}

.text {
  color: variables.$color-grey-70;
  font-size: variables.$font-size-medium;
  font-weight: 500;
}
