@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

.hintContainer {
  display: flex;
  flex-direction: column;
  gap: variables.$indent-xs;
}

.hint {
  @include mixins.transition(color);

  align-items: center;
  color: variables.$color-grey-50;
  display: flex;
  gap: variables.$indent-xxs;

  .checkbox {
    height: 14px;
    width: 14px;
  }

  .hintText {
    @include typography.s-medium;
  }

  &.isActive {
    color: variables.$color-black;
  }
}
