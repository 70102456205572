@use 'styles/variables';
@use 'styles/mixins';

.footer {
  text-align: center;
}

.container {
  align-items: center;
  column-gap: variables.$indent-l;

  display: flex;
  flex-wrap: wrap;
  row-gap: variables.$indent-m;
}

.innerWrapper {
  display: flex;
  flex: 0 1 auto;
}
