@use 'styles/variables';
@use 'styles/mixins';

.breadcrumbs {
  display: flex;
  list-style: none;
}

.breadcrumbsItem {
  @include mixins.m-medium();

  align-items: center;

  display: flex;
}

.link {
  color: variables.$color-grey-10;

  &:hover {
    color: variables.$color-grey-30;
  }

  &:active {
    color: variables.$color-grey-20;
  }
}

.separator {
  color: variables.$color-grey-70;
  white-space: pre;
}

.active .link,
.active .label {
  color: variables.$color-grey-70;
}
