@use 'styles/mixins';

.profile {
  max-width: 496px;
}

@include mixins.less-than-md {
  .profile {
    max-width: initial;
  }
}
