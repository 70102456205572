@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.transition(
    variables.$transition-duration-300,
    grid-template-rows,
    visibility
  );

  background-color: variables.$color-white;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 0fr;
  visibility: hidden;

  &.expanded {
    grid-template-rows: 1fr;
    visibility: visible;
  }

  .content {
    min-height: 0;
    min-width: 100%;
  }
}

@media (max-width: variables.$breakpoint-sm) {
  .container {
    transition: none;
  }
}
