@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/mixins/typography';

$nav-bar-height: 73px;

.mobileNavigationContent {
  height: 100%;
  overflow-y: scroll;
}

.mobileNavigationContainer {
  align-items: center;
  background-color: variables.$color-grey-100;
  box-shadow:
    0 0 12px 0 rgba(0, 16, 74, 0.25),
    0 0 4px 0 rgba(0, 16, 74, 0.1);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: variables.$indent-m;
  position: fixed;
  top: $nav-bar-height;
  width: 100%;
}

.mobileSection {
  display: flex;
  flex-direction: column;
  gap: variables.$indent-xxs;

  &:not(:last-child) {
    margin-bottom: variables.$indent-xs;
  }
}

.mobileOptionWrapper {
  padding: variables.$indent-s variables.$indent-xs;
}

.mobileOptionButton {
  text-align: left;
}

.mobileHeaderTitle {
  @include typography.l-medium;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
