@use 'styles/variables';
@use 'styles/mixins';

$nav-bar-max-width: 1216px;

.container {
  align-items: center;
  background-color: variables.$color-grey-100;
  border-bottom: 1px solid variables.$color-grey-90;
  display: flex;
  justify-content: center;
  left: 0;
  padding-block: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: $nav-bar-max-width;
  padding-inline: variables.$indent-m;
}

.img {
  flex-shrink: 0;
  width: 176px;
}

@include mixins.less-than-md {
  .container {
    padding-block: variables.$indent-m;
  }
}
