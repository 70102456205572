@use 'styles/variables';

.paymentModel {
  align-items: center;
  display: flex;
}

.iconContainer {
  margin-right: variables.$indent-xxs;
}

.revShare {
  color: variables.$color-magenta-40;
}

.cpa {
  color: variables.$color-purple-40;
}
