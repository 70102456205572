@use 'styles/variables';
@use 'styles/mixins';

.wrapper {
  align-items: center;
  display: flex;

  .errorText {
    @include mixins.m-regular;

    color: variables.$color-grey-70;
    margin-bottom: 0;
    margin-right: variables.$indent-xs;
  }
}
