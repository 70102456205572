@use '../variables';

@mixin less-than-lg {
  @media (max-width: variables.$breakpoint-lg) {
    @content;
  }
}

@mixin less-than-md {
  @media (max-width: variables.$breakpoint-md) {
    @content;
  }
}

@mixin less-than-sm {
  @media (max-width: variables.$breakpoint-sm) {
    @content;
  }
}
