@use 'styles/variables';
@use 'styles/mixins';

.button {
  @include mixins.transition(color, opacity);

  display: flex;
  opacity: 0.5;

  &:hover,
  &:active {
    opacity: 1;
  }
}

.mainContainer {
  align-items: center;
  color: variables.$color-grey-50;
  display: flex;

  &:hover {
    color: variables.$color-grey-30;
  }

  &:active {
    color: variables.$color-grey-10;
  }
}

.linkWrapper {
  overflow: hidden;
}

.ellipsisWrapper {
  display: block;
  margin-right: variables.$indent-xxs;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
