@use '../variables';
@use './common';
@use './typography.scss';

@mixin listItem($hasHover: true) {
  @include common.transition(background-color, color);
  @include typography.l-regular;

  align-items: center;
  border-radius: variables.$border-radius-m;
  color: variables.$color-grey-10;
  column-gap: variables.$indent-xs;
  cursor: pointer;
  display: flex;
  padding: variables.$indent-s;
  user-select: none;

  &.selected {
    background-color: variables.$color-grey-98;
  }

  @if $hasHover {
    &:hover {
      background-color: variables.$color-grey-98;
    }
  }

  &:not(:last-child) {
    margin-bottom: variables.$indent-xxs;
  }

  .label {
    flex-grow: 1;
  }

  .rightLabel {
    color: variables.$color-grey-50;
  }

  &.small {
    @include typography.m-regular-slh;
  }

  &.large {
    @include typography.l-regular;
  }
}
