@use 'styles/variables';

.linksWrapper {
  align-items: center;
  display: flex;
  gap: variables.$indent-l;
}

.keyValueGrid {
  margin-bottom: variables.$indent-xl;
}
