@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.m-medium;

  color: variables.$color-grey-50;
}

.link {
  display: inline-block;
  margin-left: variables.$indent-xs;
}
