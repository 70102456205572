@use 'styles/variables';

.text {
  color: variables.$color-grey-50;
  margin-bottom: variables.$indent-m;

  &:last-of-type {
    margin-bottom: variables.$indent-xl;
  }
}
