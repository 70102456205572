@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-l;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.table {
  background-color: variables.$color-white;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 1;
  isolation: isolate;
  overflow-x: auto;
  overflow-y: hidden;

  &.withEmpty {
    overflow-x: hidden;
  }
}

.loadMoreStatusContainer:not(:empty) {
  padding: variables.$indent-m 0;
}
