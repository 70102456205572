@use 'styles/variables';

.container {
  align-self: center;
  display: inline-flex;
  position: relative;
}

.withTopIndent {
  top: (variables.$indent-m / -2);
}

.withOnClick {
  cursor: pointer;
}
