@use 'styles/variables';

.input {
  margin-bottom: variables.$indent-m;
}

.deleteButton {
  margin-bottom: variables.$indent-xs;
}

.card {
  margin-bottom: variables.$indent-m;

  &:last-child {
    margin-bottom: variables.$indent-l;
  }
}
