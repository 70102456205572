@use 'styles/variables';

.button {
  align-items: center;
  color: variables.$color-grey-10;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.activeButton,
.activeButton:not(:disabled):hover {
  background-color: variables.$color-white;
  color: variables.$color-grey-30;
}

.buttonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}

.innerButtonContent {
  align-items: center;
  display: flex;
  gap: variables.$indent-xxs;
}
