@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;

  &.fullWidth {
    flex-basis: 100%;
  }
}

.searchContainer {
  align-items: center;
  background-color: variables.$color-white;
  display: flex;
  gap: variables.$indent-m;
  padding-right: variables.$indent-s;

  &.expanded {
    border-bottom: 1px solid variables.$color-grey-95;
  }
}

.searchForm {
  @include mixins.inputSearchContainer;
}

.searchIconWrapper {
  display: flex;
  left: variables.$indent-l;
  position: absolute;
}

.clearIconWrapper {
  display: flex;
  position: absolute;
  right: 0;
}

.searchInput {
  @include mixins.inputSearch;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.icon {
  color: variables.$color-grey-70;
}

.activeIcon {
  color: variables.$color-grey-50;
}

@include mixins.less-than-sm {
  .searchForm {
    border-left: 0;
    border-radius: 0;
    border-right: 0;

    &.withoutBorderBottomRadius {
      border-radius: 0;
    }
  }
}
