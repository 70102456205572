@use 'styles/variables';

.skeleton {
  animation: blink variables.$animation-duration infinite ease-in-out;
  background-color: variables.$color-grey-95;
  border-radius: variables.$border-radius-s;
}

@keyframes blink {
  0%,
  100% {
    background-color: variables.$color-grey-95;
  }

  50% {
    background-color: variables.$color-special-base-bg;
  }
}
