@use 'styles/variables';

.card {
  width: 304px;
}

.cardBody {
  margin-bottom: variables.$indent-m;
}

.linksWrapper {
  display: flex;
  gap: variables.$indent-s;
}
