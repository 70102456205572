@use 'styles/mixins';
@use 'styles/variables';

.select.select {
  position: static;
}

.select {
  @include mixins.input;
  height: 24px;
}

.disabled {
  color: variables.$color-grey-70;
}
