@use 'styles/variables';
@use 'styles/mixins';

/* stylelint-disable selector-class-pattern */
.toastContainer {
  :global(.Toastify__toast) {
    @include mixins.error-block-shadow;
    border-radius: variables.$border-radius-l;
    min-height: variables.$line-height-medium;
    padding: variables.$indent-m;
  }

  :global(.Toastify__toast-body) {
    @include mixins.m-regular;
    color: variables.$color-grey-50;
    padding: 0;
  }

  :global(.Toastify__progress-bar) {
    height: 4px;
  }

  :global(.Toastify__toast--error) {
    border: 1px solid variables.$color-vavada-pink-40;
  }

  :global(.Toastify__progress-bar--error) {
    background: variables.$color-vavada-pink-40;
  }

  :global(.Toastify__toast--success) {
    border: 1px solid variables.$color-green-30;
  }

  :global(.Toastify__progress-bar--success) {
    background: variables.$color-green-30;
  }

  :global(.Toastify__close-button) {
    background-image: url('../../../../assets/icons/line/CloseColored.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 16px;
    opacity: 1;
    transform: translateY(2px);
    width: 16px;
  }

  :global(.Toastify__close-button) svg {
    visibility: hidden;
  }
}

@include mixins.less-than-sm {
  .toastContainer {
    bottom: initial;
    padding: 0;
    right: 0;
    top: 0;
    width: 100vw;

    :global(.Toastify__toast) {
      margin: variables.$indent-l variables.$indent-m;
    }
  }
}
/* stylelint-enable selector-class-pattern */
