@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/inter-v12-cyrillic_latin-regular.woff2')
    format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/inter-v12-cyrillic_latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/inter-v12-cyrillic_latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/inter-v12-cyrillic_latin-700.woff2') format('woff2');
}
