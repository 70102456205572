@use 'sass:list';
@use '../variables';
@use './typography.scss';

/*
  Mixin for adding transitions to properties.

  Parameters:
  - $firstArg: can either be the duration of the transition (e.g., 200ms) or a property (e.g., opacity).
  - $restArgs: the remaining arguments of the mixin, these can be properties (e.g., opacity, transform).

  Usage Examples:
  @include transition(opacity, transform);
  - will apply a transition with a duration from variables.$transition-duration-100 to opacity and transform.

  @include transition(500ms, opacity, transform);
  - will apply a transition with a duration of 500ms to opacity and transform.
*/
@mixin transition($firstArg, $restArgs...) {
  $duration: variables.$transition-duration-100;
  $properties: $firstArg $restArgs;

  @if type-of($firstArg) == number {
    $duration: $firstArg;
    $properties: $restArgs;
  }

  $transitions: ();
  @each $property in $properties {
    $transitions: list.append(
      $transitions,
      ($property $duration),
      $separator: comma
    );
  }

  transition: $transitions;
}

@mixin spinAnimation($ms: 2000ms) {
  animation: spin infinite $ms linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

@mixin tooltip-box-shadow {
  box-shadow: 0 4px 32px rgba(0, 16, 74, 0.16);
}

@mixin select-box-shadow {
  box-shadow: 4px 4px 8px 0 rgba(0, 16, 74, 0.251);
}

@mixin menu-box-shadow {
  box-shadow: 10px 0 28px 0 #0067940a;
}

@mixin error-block-shadow {
  box-shadow: 4px 4px 10px 0 #1a1a1a33;
}

@mixin paragraph {
  @include typography.l-regular;
  color: variables.$color-grey-50;

  &:not(:last-child) {
    margin-bottom: variables.$indent-s;
  }
}
