@use 'styles/variables';

.header {
  align-items: center;
  color: variables.$color-grey-50;
  display: flex;
  justify-content: space-between;
  margin-bottom: variables.$indent-s;
  padding: variables.$indent-s;
}

.dateContainer {
  align-items: center;
  display: flex;
  gap: variables.$indent-xs;
  justify-content: center;
  width: 100%;
}

.captionContainer {
  position: relative;
}
