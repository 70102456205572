@use '../../../../styles/variables';

.noValue {
  color: variables.$color-grey-10;
  font-style: italic;
}

.truncatedText {
  color: variables.$color-grey-10;
}
