@use '../../../../styles/mixins';
@use '../../../../styles/variables';

.input {
  @include mixins.input;
}

.iconWithColor {
  color: variables.$color-grey-40;
}
