@use 'styles/variables';

.statusContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flagIcon,
.loadingIcon {
  color: variables.$color-grey-70;
  margin-right: variables.$indent-xs;
}

.text {
  color: variables.$color-grey-70;
  font-size: variables.$font-size-medium;
  font-weight: 500;
}

.errorMessage {
  color: variables.$color-vavada-pink-40;
  font-size: variables.$font-size-medium;
  font-weight: 500;
  margin-right: variables.$indent-s;
}
