@use 'styles/variables';

.container {
  position: relative;
  width: fit-content;
}

.withStyleMaxContent {
  width: 100%;
}
