@use 'styles/variables';

.text {
  -webkit-box-orient: vertical;
  color: variables.$color-grey-50;
  display: -webkit-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  word-break: break-all;
}

.tooltipTrigger {
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
}
