@use '../../../../../../styles/mixins';
@use '../../../../../../styles/variables';

.control.control {
  @include mixins.l-medium;

  cursor: pointer;
  min-height: variables.$line-height-large;
  position: static;
}
