$font-family: 'Inter', sans-serif;

$header-font-size-large: 24px;
$header-font-size-medium: 20px;
$header-font-size-small: 18px;

$header-line-height-large: 32px;
$header-line-height-medium: 24px;
$header-line-height-small: 24px;

$font-size-extra-large: 18px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-size-extra-small: 10px;

$line-height-large: 24px;
$line-height-medium: 20px;
$line-height-small: 16px;
