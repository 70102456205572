@use 'styles/variables';
@use 'styles/mixins';

.innerWrapper {
  display: flex;
  flex-direction: column;
}

.balanceWrapper {
  align-items: baseline;
  display: flex;
  flex-direction: row;

  .balance {
    @include mixins.spec-header-large;

    color: variables.$color-grey-10;
    margin-bottom: 0;
    margin-right: variables.$indent-xxs;
  }

  .currency {
    @include mixins.m-medium;

    color: variables.$color-grey-10;
  }
}

.bottomCurrencyBlock {
  @include mixins.m-regular;

  color: variables.$color-grey-50;
  margin-top: variables.$indent-xxs;
}

@include mixins.less-than-md {
  .wrapper {
    grid-column: span 1;
  }
}
