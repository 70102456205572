@use 'styles/mixins';
@use 'styles/variables';

.dateTitle {
  align-items: center;
  border-bottom: 1px solid variables.$color-grey-95;
  display: flex;
  min-height: 64px;

  @include mixins.less-than-md {
    display: none;
  }
}

.dateTitle .dateText {
  align-items: center;
  color: variables.$color-grey-10;
  display: flex;
  margin-bottom: 0;
  padding-left: 20px;
  width: 100%;
}

.dateTitle .icon {
  margin-right: variables.$indent-xs;
}
