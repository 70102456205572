@use 'styles/variables';

.container {
  display: flex;
}

.groupContainer {
  display: grid;
  flex-basis: 100%;
  grid-auto-rows: min-content;

  &:not(.withExpand) > .pinned {
    border-right: 1px solid variables.$color-grey-95;
  }

  &.withExpand > :nth-child(2) {
    border-right: 1px solid variables.$color-grey-95;
    padding-left: 0;
  }

  > * {
    border-bottom: 1px solid variables.$color-grey-95;
  }
}
