@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.transition(background);

  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-l;
  cursor: pointer;
  display: flex;
  flex-basis: 240px;
  flex-direction: column;
  min-width: 240px;
  padding: variables.$indent-m;

  &:not(.active):hover {
    background: variables.$color-grey-98;
    color: variables.$color-grey-30;
  }

  &:active {
    background: none;
  }
}

.container:not(.active):hover .iconContainer {
  background: none;
  color: variables.$color-grey-30;
}

.iconContainer {
  @include mixins.transition(background);

  background: variables.$color-grey-98;
  border-radius: variables.$border-radius-l;
  color: variables.$color-grey-50;
  padding: variables.$indent-s;

  &:hover {
    color: variables.$color-grey-30;
  }

  .active & {
    background: variables.$color-vavada-pink-98;
    color: variables.$color-vavada-pink-60;
  }
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: variables.$indent-l;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  @include mixins.h3;

  margin-bottom: variables.$indent-xxs;
}

.subTitle {
  @include mixins.m-regular;

  color: variables.$color-grey-50;
}
