@use 'styles/variables';
@use 'styles/mixins';

.container {
  @include mixins.transition(background-color);
  align-items: center;

  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-95;
  border-radius: variables.$border-radius-xl;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  min-width: 242px;
  padding: variables.$indent-s;
  user-select: none;

  &.notClickable {
    pointer-events: none;
  }
}

.container:hover {
  background-color: variables.$color-grey-98;

  .iconContainer {
    background-color: variables.$color-grey-95;
  }
}

.container:active {
  background-color: variables.$color-grey-95;

  .iconContainer {
    background-color: variables.$color-grey-90;
  }
}

.content {
  align-items: center;
  column-gap: variables.$indent-xs;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
}

.iconContainer {
  @include mixins.transition(background-color);

  align-items: center;
  background: variables.$color-grey-98;
  border-radius: variables.$border-radius-l;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  margin-right: variables.$indent-xs;
  width: 48px;

  .icon {
    color: variables.$color-grey-50;
  }
}

.title {
  @include mixins.l-medium;
  color: variables.$color-grey-20;
}

.subTitle {
  @include mixins.m-regular;
  color: variables.$color-grey-50;
}
