@use 'styles/variables';
@use 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@include mixins.less-than-sm {
  .container {
    gap: 28px;
  }
}
