@use 'styles/mixins';
@use 'styles/variables';

$medium-dropdown-width: 328px;
$small-dropdown-width: 224px;

.container {
  margin-top: variables.$indent-xs;
  position: absolute;
  z-index: 1;
}

.medium {
  min-width: $medium-dropdown-width;
  width: $medium-dropdown-width;
}

.small {
  min-width: $small-dropdown-width;
  width: $small-dropdown-width;
}

.withStyleMaxContent {
  width: max-content;
}

.commonStyles {
  @include mixins.dropdownContainer;
}

.withGrayOutline {
  border-color: variables.$color-grey-95;

  &:hover {
    border-color: variables.$color-grey-95;
  }
}

.leftBottomPosition {
  left: 0;
}

.rightBottomPosition {
  right: 0;
}

.leftTopPosition {
  bottom: calc(100% + 8px);
  left: 0;
}

.rightPosition {
  left: calc(100% + 8px);
  margin-top: 0;
  top: 0;
}
