@use 'styles/variables';
@use 'styles/mixins';

.errorIcon {
  max-width: 100%;
  padding: variables.$indent-xxl 0;
}

.container .header {
  margin-bottom: variables.$indent-xs;
}

.text {
  @include mixins.m-regular;
}

.text:not(:last-child) {
  margin-bottom: 0;
}

.link {
  display: flex;
  justify-content: center;
}

@include mixins.less-than-sm {
  .errorIcon {
    padding: variables.$indent-xl 34px;
  }
}
