@use '../../../../styles/variables';
@use '../../../../styles/mixins';

$border-line-width: 1px;
$checkbox-hover-small-size: 32px;
$checkbox-hover-default-size: 40px;

.checkboxButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: variables.$indent-xs;
  width: 100%;

  &:active .checkboxControl::before {
    background-color: variables.$color-grey-90;
  }
}

:not(:disabled).checkboxButton:hover .checkboxControl {
  &.small::before {
    height: $checkbox-hover-small-size;
    width: $checkbox-hover-small-size;
  }

  &.default::before {
    height: $checkbox-hover-default-size;
    width: $checkbox-hover-default-size;
  }
}

.checkboxControl {
  @include mixins.transition(background-color, color, border-color);

  align-items: center;
  background-color: variables.$color-white;
  border: $border-line-width solid variables.$color-grey-40;
  color: transparent;
  position: relative;

  &::before {
    @include mixins.transition(background-color);
    background-color: variables.$color-grey-95;
    border-radius: 50%;
    content: '';
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    z-index: -1;
  }

  &.small {
    border-radius: variables.$border-radius-xs;
    height: variables.$icon-s;
    width: variables.$icon-s;
  }

  &.default {
    border-radius: variables.$border-radius-s;
    height: variables.$icon-l;
    width: variables.$icon-l;
  }

  &.checked {
    background-color: variables.$color-grey-10;
    border: $border-line-width solid variables.$color-grey-10;
    color: variables.$color-white;
  }

  &.checked.disabled {
    background-color: variables.$color-grey-90;
    border: $border-line-width solid variables.$color-grey-90;
    color: variables.$color-white;
  }

  &:not(.checked).disabled {
    background-color: variables.$color-white;
    border: $border-line-width solid variables.$color-grey-90;
    color: variables.$color-white;
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: variables.$indent-xxs;
  justify-content: center;
  min-width: 0;
}

.label {
  @include mixins.m-medium;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: variables.$color-grey-70;
  }
}

.subLabel {
  @include mixins.s-regular;
  color: variables.$color-grey-50;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: variables.$color-grey-80;
  }
}

// double for overriding a display property
.iconContainer.iconContainer {
  display: block;
  left: -($border-line-width);
  position: relative;
  top: -($border-line-width);
}

.icon {
  display: block;
}
