@use 'styles/variables';

.keyValueGrid {
  margin-bottom: 32px;
}

.linksWrapper {
  display: flex;
  gap: variables.$indent-s;
}
