@use 'styles/variables';
@use 'styles/mixins/typography';

.form {
  margin-bottom: variables.$indent-xl;
}

.forgetPasswordLink {
  @include typography.m-medium;
}
