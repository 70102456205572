@use 'styles/variables';
@use 'styles/mixins';

$max-modal-width-lg: 576px;
$max-modal-width-md: 500px;

.modalHeader {
  align-items: center;
  border-bottom: 1px solid variables.$color-grey-95;
  display: flex;
  justify-content: space-between;
  padding: variables.$indent-m variables.$indent-l;

  .closeButton {
    border-radius: variables.$border-radius-s;
  }
}

.content {
  padding: variables.$indent-l;
}

.title {
  @include mixins.h2;
  color: variables.$color-grey-10;
}

.subTitle {
  @include mixins.m-regular;
  color: variables.$color-grey-50;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 48px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: variables.$indent-xxs;
}

.backButton {
  margin-right: variables.$indent-xs;
}

.modalContent {
  @include mixins.transition(300ms, transform);

  background-color: variables.$color-white;
  border: 0;
  border-radius: 0;
  bottom: 0;
  max-width: $max-modal-width-lg;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX($max-modal-width-lg);
  width: 100%;

  &.afterOpen {
    transform: translateX(0);
  }

  &.beforeClose {
    transform: translateX($max-modal-width-lg);
  }

  &:focus {
    outline: none;
  }
}

@include mixins.less-than-md {
  .modalContent {
    max-width: $max-modal-width-md;

    &.beforeClose {
      transform: translateX($max-modal-width-md);
    }
  }
}

@include mixins.less-than-sm {
  .modalHeader {
    padding: variables.$indent-m;
  }

  .content {
    padding: variables.$indent-l variables.$indent-m;
  }

  .modalContent {
    max-width: initial;
    min-width: variables.$min-app-width;
  }
}
